export const COLORS = {
    white: "#fff",
    black: "#000",

    main900: "#FF5B8D",
    main500: "#F083A4",
    main300: "#F6A9BD",
    main100: "rgba(246, 169, 189, 0.30)",

    sec900: "#1490D1",
    sec500: "#70C4F0",
    sec300: "#5BC7FF",
    sec100: "#CFEEFF",

    gray900: "#31353B",
    gray700: "#717171",
    gray600: "#606060",

    bg: "#FFF7DD",
};
