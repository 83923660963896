import { Box, Typography } from "@mui/material";
import Footer from "../components/footer";
import Container from "../components/container";
import Row from "../components/row";
import Col from "../components/col";
import { COLORS } from "../values/colors";
import { ReactSVG } from "react-svg";
// import iPhone22 from "../assets/img/iPhone22.png";
import group44 from "../assets/img/group44.png";
import machine from "../assets/img/machine-learning.svg";
import logo from "../assets/img/logo.svg";
import emoticonBlue from "../assets/img/emoticon-blue.svg";
import journalBlue from "../assets/img/journal-blue.svg";
import testBlue from "../assets/img/test-blue.svg";
import notesBlue from "../assets/img/notes-blue.svg";
import counselingBlue from "../assets/img/counseling-blue.svg";
import visualsBlue from "../assets/img/visuals-blue.svg";
import useWindowDimensions from "../helpers";
import coin1 from "../assets/img/coin1.svg";
import coin2 from "../assets/img/coin2.svg";
import coin3 from "../assets/img/coin3.svg";
import techBg from "../assets/img/tech-bg.png";

const AboutPage = () => {
    const { windowDimensions } = useWindowDimensions();

    return (
        <Container scroll={true}>
            <Container containerProps={{ py: "10vh" }}>
                <Section1 windowDimensions={windowDimensions} />
                <Section2 windowDimensions={windowDimensions} />
                <Section3 windowDimensions={windowDimensions} />
            </Container>

            <Footer />
        </Container>
    );
};

export default AboutPage;

const Section3 = ({ windowDimensions }: any) => {
    const boxes = [
        {
            img: journalBlue,
            title: "Journal\nwriting",
            text: "Journal writing, often regarded as a therapeutic practice, takes on a new dimension with the integration of token rewards.  By linking journaling efforts to token rewards, BOM not only encourages regular participation but also provides users with an additional avenue for personal growth and positive reinforcement in their mental health journey. Also, BOM offers AI recommnedations based on your writing style and input.",
        },
        {
            img: testBlue,
            title: "Self-assessment\ntests",
            text: "Participating in self-assessment tests becomes a dynamic and rewarding experience through BOM’s token reward system. Users are incentivized to engage in self-reflection and personal growth by earning tokens for completing these assessments. This innovative approach transforms the self-assessment process into a proactive step toward mental well-being, as users receive tangible rewards for their commitment to self-awareness.",
        },
        {
            img: counselingBlue,
            title: " Virtual\nCounseling",
            text: "1v1 virtual counseling is essential for providing individuals with personalized and confidential support, addressing their unique mental health needs in a tailored and empathetic manner. This form of counseling fosters a safe space for open dialogue, facilitating the exploration of emotions, coping strategies, and the development of personalized solutions for mental well-being.",
        },
        {
            img: emoticonBlue,
            title: "Mood\ntracker",
            text: `BOM revolutionizes the act of recording one's mood by introducing a token reward system, making the process inherently more engaging and beneficial.  This innovative approach transforms mood tracking into a proactive and rewarding aspect of self-care, providing users with immediate incentives for maintaining emotional awareness.`,
        },
        {
            img: notesBlue,
            title: "Meditation Sounds",
            text: "BOM introduces an immersive meditation experience by offering personalized sound recommendations tailored to individual preferences. Users can explore a curated selection of calming and therapeutic meditation sounds, enhancing their mental well-being journey. As a unique incentive, BOM rewards users with tokens for actively engaging in mindfulness practices through listening to meditation sounds.",
        },
        {
            img: visualsBlue,
            title: "Soothing Visual Videos",
            text: "Soothing visual videos play a crucial role in mental health by providing a visually calming and immersive experience, promoting relaxation and stress reduction. Incorporating these videos into a wellness routine can contribute to improved mood, enhanced focus, and a heightened sense of well-being.",
        },
    ];

    return (
        <Col
            sx={{
                pt: "18vh",
                justifyContent: "center",
                px: "8vw",
                width: "100vw",
                // border: "1px solid red",
            }}
        >
            <Col sx={{ alignItems: "center" }}>
                <ReactSVG
                    src={logo}
                    style={{
                        width:
                            windowDimensions.width < 320
                                ? 172
                                : windowDimensions.width < 745
                                ? 296
                                : windowDimensions.width < 1280
                                ? 368
                                : 373,
                        height: "auto",
                    }}
                />
                <Typography
                    sx={{
                        color: COLORS.main500,
                        fontWeight: "500",
                        fontSize: "3rem",
                        mt: "4vh",
                    }}
                >
                    Services
                </Typography>
            </Col>
            <Col
                sx={{
                    mt: "2vh",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    // border: "1px solid red",
                }}
            >
                <Col
                    sx={{
                        gap: 4,
                        // width: "100vw",

                        flexDirection:
                            windowDimensions.width < 745 ? "column" : "row",
                        flexWrap:
                            windowDimensions.width < 745 ? undefined : "wrap",
                    }}
                >
                    {boxes.map((box, idx) => (
                        <BoxItem {...box} key={`box-${idx}`} />
                    ))}
                </Col>
            </Col>
        </Col>
    );
};

const BoxItem = (item: any) => {
    const { windowDimensions } = useWindowDimensions();
    const { width: screenWidth } = windowDimensions;
    const mediumScreen = screenWidth < 745 && screenWidth > 320;

    return (
        <Col
            sx={{
                mt: "4vh",
                width: screenWidth > 745 ? "30%" : "100%",
            }}
        >
            <Row
                sx={{
                    gap: 2,
                    justifyContent: mediumScreen ? "center" : "flex-start",
                }}
            >
                <ReactSVG src={item.img} style={{ height: 50, width: 50 }} />
                <Typography
                    sx={{
                        fontSize: "1.25rem",
                        fontWeight: "700",
                        mt: "2vh",
                        whiteSpace: "pre",
                        wordWrap: "break-word",
                    }}
                >
                    {item.title}
                </Typography>
            </Row>
            <Typography
                sx={{ mt: 2, textAlign: mediumScreen ? "center" : "left" }}
            >
                {item.text}
            </Typography>
        </Col>
    );
};

const Section2 = ({ windowDimensions }: any) => {
    console.log("s2 width=", windowDimensions.width);
    const { screenType } = useWindowDimensions();
    return (
        <Col
            sx={{
                alignItems: "center",
                justifyContent: "center",
                alignSelf: "center",
                width: "100%",
            }}
        >
            <Box
                sx={{
                    py: "10rem",
                    // bgcolor: "green",
                    px: ["sm", "md"].includes(screenType) ? "2vw " : 0,
                }}
            >
                <Typography
                    sx={{
                        fontSize: [
                            "1.5rem",
                            "1.5rem",
                            "1.875rem",
                            "2rem",
                            "2rem",
                        ],
                        fontWeight: "700",
                        color: COLORS.main300,
                    }}
                >
                    Mission
                </Typography>
                <Typography
                    sx={{
                        fontSize: [
                            "1.1875rem",
                            "1.1875rem",
                            "2.0625rem",
                            "2.0625rem",
                            "2.0625rem",
                        ],
                        fontWeight: "700",
                        color: COLORS.gray700,
                        maxWidth: "63.4375rem",
                        mt: 2,
                    }}
                >
                    Our mission is to foster a culture of self-care,
                    self-awareness, and personal growth, ensuring that every
                    step taken toward mental wellness is not only meaningful but
                    also recognized and rewarded.
                </Typography>

                <Row
                    sx={{
                        mt:
                            screenType === "sm"
                                ? "4rem"
                                : screenType === "md"
                                ? "6rem"
                                : "18rem",

                        gap: "1vw",
                        justifyContent: "space-between",
                    }}
                >
                    <ReactSVG
                        src={coin1}
                        style={{
                            width: "13.3125rem",
                            height: "13.3125rem",
                            marginLeft: "-1vw",
                        }}
                    />
                    <Col sx={{}}>
                        <Typography
                            sx={{
                                fontSize: [
                                    "1.5rem",
                                    "1.5rem",
                                    "1.875rem",
                                    "2rem",
                                    "2rem",
                                ],
                                fontWeight: "700",
                                color: COLORS.main300,
                            }}
                        >
                            Vision
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: [
                                    "1.1875rem",
                                    "1.1875rem",
                                    "2.0625rem",
                                    "2.0625rem",
                                    "2.0625rem",
                                ],
                                fontWeight: "700",
                                color: COLORS.gray700,
                                maxWidth: "63.4375rem",
                                mt: 2,
                            }}
                        >
                            At BOM, our vision is to redefine the landscape of
                            mental health and well-being through a revolutionary
                            fusion of technology and self-discovery.
                        </Typography>
                    </Col>
                </Row>
            </Box>
            <Box
                sx={{
                    mt: "12vh",
                    bgcolor: "#CCEBFC",
                    width: "100%",
                    // alignItems: "center",
                    // justifyContent: "center",
                    position: "relative",
                    // border: "1px solid red",
                }}
            >
                <Row
                    sx={{
                        gap: "6vw",
                        pr: "12vw",
                        pl: "4vw",
                        justifyContent: "center",
                        alignItems: "center",
                        py: "8vh",
                        // border: "1px solid red",
                    }}
                >
                    <ReactSVG
                        src={machine}
                        style={{
                            width:
                                windowDimensions.width < 320
                                    ? 180
                                    : windowDimensions.width < 745
                                    ? 324
                                    : windowDimensions.width < 1280
                                    ? 420
                                    : 504,
                        }}
                    />
                    <Col sx={{}}>
                        <Typography
                            sx={{ color: COLORS.gray700, fontSize: "2rem" }}
                        >
                            AI Algorithms and
                            <Typography
                                sx={{
                                    color: COLORS.main500,
                                    fontSize: "2rem",
                                    fontWeight: "700",
                                    display: "inline",
                                    ml: ".7vw",
                                }}
                            >
                                Machine Learning
                            </Typography>
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "1.25rem",
                                mt: 2,
                                maxWidth: "39.8125rem",
                            }}
                        >
                            Introducing a groundbreaking advancement in mental
                            health technology, BOM’s proudly unveils its
                            innovative AI feature designed to identify potential
                            suicidal thoughts. Rooted in a commitment to
                            prioritize user well-being, BOM's cutting-edge
                            system leverages advanced algorithms developed
                            through the careful analysis of real suicide
                            letters. By accumulating and comprehensively
                            understanding the language nuances associated with
                            such critical situations, BOM aims to provide users
                            with a tool that can recognize and respond to
                            concerning patterns, offering timely and
                            compassionate support. This revolutionary AI feature
                            embodies BOM's dedication to harnessing technology
                            for the betterment of mental health, fostering a
                            safer and more proactive approach to suicide
                            prevention within its platform.
                        </Typography>
                    </Col>
                </Row>
                <img
                    src={techBg}
                    style={{
                        width: "100%",
                        height: "100%",
                        opacity: 0.56,
                        position: "absolute",
                        top: 0,
                        right: 0,
                    }}
                />
            </Box>
        </Col>
    );
};

const Section1 = ({ windowDimensions }: any) => {
    const { screenType } = useWindowDimensions();
    return (
        <Col sx={{ alignItems: "center", px: "2vw" }}>
            <Row
                sx={{
                    justifyContent: "center",
                    gap: "2vw",
                    ml: "4vw",

                    // bgcolor: "yellow",
                }}
            >
                <Col
                    sx={{
                        flex: 1,
                        // border: "1px solid red"
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: [
                                "1.5rem",
                                "1.5rem",
                                "2rem",
                                "2rem",
                                "2rem",
                            ],
                            color: COLORS.gray700,
                            textAlign: "left",
                        }}
                    >
                        About
                        <Typography
                            sx={{
                                fontSize: "inherit",
                                fontWeight: "700",
                                color: COLORS.main300,
                                display: "inline",
                                ml: ".6vw",
                            }}
                        >
                            us
                        </Typography>
                    </Typography>

                    <Box
                        sx={{
                            mt: 2,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: [
                                    "1.1875rem",
                                    "1.1875rem",
                                    "2.0625rem",
                                    "2.0625rem",
                                    "2.0625rem",
                                ],
                                fontWeight: "700",
                                color: COLORS.gray700,
                                mt: 2,
                                maxWidth: "37.4375rem",
                            }}
                        >
                            Introducing a groundbreaking approach to mental
                            health and well-being,
                            <Typography
                                sx={{
                                    color: COLORS.main300,
                                    fontSize: [
                                        "1.1875rem",
                                        "1.1875rem",
                                        "2.0625rem",
                                        "2.0625rem",
                                        "2.0625rem",
                                    ],
                                    fontWeight: "700",
                                    display: "inline",
                                }}
                            >
                                {` BOM `}
                            </Typography>
                            unveils new services designed to empower users on
                            their personal growth journey.
                        </Typography>
                    </Box>
                    <Typography
                        sx={{
                            fontSize: [
                                "1",
                                "1",
                                "1.25rem",
                                "1.25rem",
                                "1.25rem",
                            ],
                            color: COLORS.gray700,
                            mt: 3,
                            maxWidth: "37.4375rem",
                        }}
                    >
                        Through innovative features such as journal writing,
                        self-assessment tests, and mood tracking, BOM combines
                        reflective practices with a unique token reward system.
                        Users are not only encouraged to actively engage in
                        self-care but are also rewarded with tokens,
                        transforming the pursuit of mental wellness into a
                        fulfilling and incentivized experience.
                    </Typography>
                </Col>
                <Box sx={{ position: "relative" }}>
                    <img
                        src={group44}
                        style={{
                            width:
                                windowDimensions.width < 321
                                    ? "18rem"
                                    : windowDimensions.width < 745
                                    ? "23rem"
                                    : "40rem",
                            height: "auto",
                        }}
                    />
                </Box>
            </Row>
        </Col>
    );
};
