import Footer from "../components/footer";
import Container from "../components/container";
import { Box, Typography } from "@mui/material";
import bg_svg1 from "../assets/img/bg_svg1.svg";
import bg_svg2 from "../assets/img/bg-svg2.svg";
import bg_svg3 from "../assets/img/bg_svg3.svg";
import bg_svg4 from "../assets/img/bg_svg4.svg";
import { COLORS } from "../values/colors";
import Row from "../components/row";
import { ReactSVG } from "react-svg";
import coin1 from "../assets/img/coin1.svg";
import coin2 from "../assets/img/coin2.svg";
import coin3 from "../assets/img/coin3.svg";
import group11 from "../assets/img/group11.png";
import iPhone1 from "../assets/img/iPhone1.svg";
import iPhone2 from "../assets/img/iPhone2.svg";
import iPhone2p from "../assets/img/iPhone2.png";
import iPhone3 from "../assets/img/iPhone3.svg";
import journal from "../assets/img/journal.svg";
import test from "../assets/img/test.svg";
import emoticon_great from "../assets/img/emoticon_great.svg";
import Col from "../components/col";
import React from "react";
import moodup1 from "../assets/img/moodup-1.png";
import moodup2 from "../assets/img/moodup-2.png";
import testBlue from "../assets/img/test-blue.svg";
import notesBlue from "../assets/img/notes-blue.svg";
import visualsBlue from "../assets/img/visuals-blue.svg";
import counselingBlue from "../assets/img/counseling-blue.svg";
import useWindowDimensions from "../helpers";
import { Image } from "@mui/icons-material";

export default function Root() {
    return (
        <Container scroll={true}>
            <Container>
                <div
                    style={{
                        backgroundImage: `url(${bg_svg1})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPositionY: 250,
                        backgroundSize: "cover",
                        paddingBottom: 100,
                    }}
                >
                    <Section1 />
                </div>
                <div
                    style={{
                        backgroundImage: `url(${bg_svg2})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPositionY: "8.1vh",
                        backgroundSize: "cover",
                        paddingBottom: "8vh",
                    }}
                >
                    <Section2 />
                </div>
                <Box
                    sx={{
                        bgcolor: "#CFEEFF",
                        position: "relative",
                        width: "100%",
                    }}
                >
                    {/* <ReactSVG
                        src={bg_svg3}
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            opacity: 0.56,
                            top: 0,
                            right: 0,
                        }}
                    /> */}
                    {/* <Box sx={{ position: "absolute", top: 0, right: 0 }}> */}
                    <Section3 />
                    {/* </Box> */}
                </Box>

                <div
                    style={{
                        backgroundImage: `url(${bg_svg4})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPositionY: "center start",
                        backgroundSize: "cover",
                        paddingTop: "34vh",
                        backgroundColor: COLORS.sec100,
                    }}
                >
                    <Section4 />
                </div>
            </Container>
            <Footer />
        </Container>
    );
}

const Section4 = () => {
    const { screenType } = useWindowDimensions();
    const BOXES = [
        {
            id: "1",
            img: notesBlue,
            title: "Meditation Sounds",
            text: "BOM introduces an immersive meditation experience by offering personalized sound recommendations tailored to individual preferences. Users can explore a curated selection of calming and therapeutic meditation sounds, enhancing their mental well-being journey. As a unique incentive, BOM rewards users with tokens for actively engaging in mindfulness practices through listening to meditation sounds.",
        },
        {
            id: "2",
            img: testBlue,
            title: "Self-assessment\ntests",
            text: "Participating in self-assessment tests becomes a dynamic and rewarding experience through BOM’s token reward system. Users are incentivized to engage in self-reflection and personal growth by earning tokens for completing these assessments. This innovative approach transforms the self-assessment process into a proactive step toward mental well-being, as users receive tangible rewards for their commitment to self-awareness.",
        },
        {
            id: "3",
            img: visualsBlue,
            title: "Soothing Visual\nVideos",
            text: "Soothing visual videos play a crucial role in mental health by providing a visually calming and immersive experience, promoting relaxation and stress reduction. Incorporating these videos into a wellness routine can contribute to improved mood, enhanced focus, and a heightened sense of well-being.",
        },
        {
            id: "4",
            img: counselingBlue,
            title: "Virtual Counseling",
            text: "BOM's token-rewarded virtual 1 on 1 counseling adds tremendous value to users seeking personalized mental health support. By incentivizing these sessions, BOM encourages individuals to actively prioritize their mental well-being through direct and confidential interactions with qualified professionals.",
        },
    ];

    const blueImg = {
        sm: "2.97394rem",
        md: "2.97394rem",
        lg: "5.81256rem",
        xl: "5.81256rem",
    } as any;
    console.log("ST", screenType);
    return (
        <Col
            sx={{
                pt: "22vh",
                // bgcolor: "pink",
                // pb: "10vh",
            }}
        >
            <Col sx={{ my: "4vh", alignItems: "center", px: "8vw" }}>
                <Typography
                    sx={{
                        color: COLORS.sec900,
                        fontSize: ["sm", "md"].includes(screenType)
                            ? "1.5rem"
                            : "3rem",
                        fontWeight: "700",
                        whiteSpace: "break-spaces",
                    }}
                >
                    Premium Services Coming Soon
                </Typography>
            </Col>
            <Col
                sx={{
                    alignItems: "center",
                    bgcolor: COLORS.bg,
                    // border: "1px solid red",
                    pb: "8vh",
                }}
            >
                <Row sx={{ flex: 1, gap: 6, mt: 6, px: "8vw" }}>
                    {[BOXES[0], BOXES[1]].map((box) => (
                        <Col sx={{ flex: 1 }} key={`box-row-1-${box.id}`}>
                            <Row sx={{ gap: 2, flexWrap: "wrap" }}>
                                <img
                                    src={box.img}
                                    style={{
                                        width: blueImg[screenType],
                                        height: "auto",
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: ["sm", "md"].includes(
                                            screenType
                                        )
                                            ? "1.25rem"
                                            : "2rem",
                                        fontWeight: "700",
                                        whiteSpace: "break-spaces",
                                    }}
                                >
                                    {box.title}
                                </Typography>
                            </Row>
                            <Box sx={{ mt: 2 }}>
                                <Typography
                                    sx={{
                                        textAlign: "left",
                                        maxWidth: "28.09788rem",
                                    }}
                                >
                                    {box.text}
                                </Typography>
                            </Box>
                        </Col>
                    ))}
                </Row>
                <Row
                    sx={{
                        flex: 1,
                        gap: 6,
                        mt: 6,
                        px: "8vw",
                    }}
                >
                    {[BOXES[2], BOXES[3]].map((box) => (
                        <Col sx={{ flex: 1 }} key={`box-row-2-${box.id}`}>
                            <Row sx={{ gap: 2, flexWrap: "wrap" }}>
                                <img
                                    src={box.img}
                                    style={{
                                        width: blueImg[screenType],
                                        height: "auto",
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: ["sm", "md"].includes(
                                            screenType
                                        )
                                            ? "1.25rem"
                                            : "2rem",
                                        fontWeight: "700",
                                        whiteSpace: "break-spaces",
                                    }}
                                >
                                    {box.title}
                                </Typography>
                            </Row>

                            <Box sx={{ mt: 2 }}>
                                <Typography
                                    sx={{
                                        textAlign: "left",
                                        maxWidth: "28.09788rem",
                                    }}
                                >
                                    {box.text}
                                </Typography>
                            </Box>
                        </Col>
                    ))}
                </Row>
            </Col>
        </Col>
    );
};

const Section2 = () => {
    const { screenType } = useWindowDimensions();
    const pic2sz = {
        xl: "20.8125rem",
        lg: "18.53306rem",
        md: "15.47031rem",
        sm: " 12.375rem",
    } as any;

    return (
        <Col
            sx={{
                alignItems: "center",
                // border: "2px solid red",
                mt: "10vh",
            }}
        >
            <Row
                sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    px: "8vw",
                    gap: 20,
                }}
            >
                <Col sx={{ flex: 1, alignSelf: "flex-start" }}>
                    <Typography
                        sx={{
                            fontSize: ["sm", "md"].includes(screenType)
                                ? "2rem"
                                : "3rem",
                            fontWeight: "700",
                            color: COLORS.main900,
                        }}
                    >
                        Services
                    </Typography>
                    <Row sx={{ mt: "6vh", gap: 2, flex: 1, display: "flex" }}>
                        <Typography
                            sx={{
                                fontSize: ["sm", "md"].includes(screenType)
                                    ? "1.25rem"
                                    : "2rem",
                                fontWeight: 700,
                                alignSelf: "flex-end",
                                mb: 1,
                            }}
                        >
                            Journal writing
                        </Typography>
                        <ReactSVG
                            src={journal}
                            width={
                                ["sm", "md"].includes(screenType)
                                    ? "2.625rem"
                                    : undefined
                            }
                            height="auto"
                        />
                    </Row>
                    <Typography
                        sx={{
                            mt: 1,
                            lineHeight:
                                screenType === "sm" ? "1.8125rem" : "2.375rem",
                            fontSize: ["sm", "md"].includes(screenType)
                                ? "0.9375rem"
                                : "1.25rem",
                            maxWidth: "35rem",
                        }}
                    >
                        {
                            "Journal writing, often regarded as a therapeutic practice, takes on a new dimension with the integration of token rewards.  By linking journaling efforts to token rewards, BOM not only encourages regular participation but also provides users with an additional avenue for personal growth and positive reinforcement in their mental health journey. Also, BOM offers AI recommnedations based on your writing style and input."
                        }
                    </Typography>
                </Col>
                <Col
                    sx={{
                        flex: 1,
                        alignContent: "center",
                        justifyContent: "center",
                        pt: "4vh",
                        // border: "1px solid red",
                    }}
                >
                    <ReactSVG
                        src={iPhone2}
                        style={{
                            width: pic2sz[screenType],
                            height: "auto",
                            alignSelf:
                                screenType === "xl" ? undefined : "center",
                        }}
                    />
                </Col>
            </Row>
        </Col>
    );
};

const Section3 = () => {
    const { screenType } = useWindowDimensions();
    const pic3sz = {
        xl: "20.8125rem",
        lg: "18.52744rem",
        md: "15.43956rem",
        sm: "12.375rem",
    } as any;

    return (
        <Col
            sx={{
                alignItems: "center",
                justifyContent: "center",
                px: "4vw",
                mt: ["xl", "lg"].includes(screenType) ? -5 : -5,
                pt: "5vh",
                // bgcolor: "red",
                // bgcolor: COLORS.sec100,
            }}
        >
            <Col sx={{ flex: 1 }}>
                <Row
                    sx={{
                        gap: 10,
                        justifyContent: "center",
                        flexDirection: ["xl", "lg"].includes(screenType)
                            ? undefined
                            : "column-reverse",
                    }}
                >
                    <ReactSVG
                        src={iPhone3}
                        style={{ width: pic3sz[screenType], height: "auto" }}
                        // style={{ width: "20.8125rem", height: "auto" }}
                    />
                    <Box sx={{ flex: 1 }}>
                        <Row sx={{ gap: 6 }}>
                            <Typography
                                sx={{
                                    fontSize: ["sm", "md"].includes(screenType)
                                        ? "1.25rem"
                                        : "2rem",
                                    fontWeight: "700",
                                    alignSelf: "flex-end",
                                    // mb: 1,
                                }}
                            >
                                Mood Tracking
                            </Typography>
                            <ReactSVG src={test} />
                        </Row>
                        <Typography
                            sx={{
                                mt: 2,
                                maxWidth: "34.77306rem",
                                lineHeight:
                                    screenType === "sm"
                                        ? "1.8125rem"
                                        : "2.375rem",
                                fontSize: ["sm", "md"].includes(screenType)
                                    ? "0.9375rem"
                                    : "1.25rem",
                            }}
                        >
                            BOM transforms the act of mood tracking into a
                            dynamic and rewarding experience by introducing a
                            token reward system. Users are encouraged to
                            actively participate in their mental well-being
                            journey by inputting their moods, earning tokens as
                            a recognition of their commitment. This innovative
                            approach not only promotes regular self-reflection
                            but also creates a positive feedback loop,
                            motivating users to consistently engage in mood
                            tracking.
                        </Typography>
                    </Box>
                </Row>
            </Col>
            <Col
                sx={{
                    mt: 9,
                    justifyContent: "center",
                    alignItems: "center",
                    // border: "2px solid red",
                }}
            >
                <Row
                    sx={{
                        gap: 10,
                        justifyContent: "center",
                    }}
                >
                    <Box sx={{}}>
                        <Row
                            sx={{
                                gap: 2,
                                flexWrap: "nowrap",
                                justifyContent: "flex-start",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: ["sm", "md"].includes(screenType)
                                        ? "1.25rem"
                                        : "2rem",

                                    fontWeight: "700",
                                    mb: 1,
                                    whiteSpace: "break-spaces",
                                }}
                            >
                                Mood-Up Challenge
                            </Typography>

                            <ReactSVG
                                src={emoticon_great}
                                style={{
                                    width: ["sm", "md"].includes(screenType)
                                        ? "2.5625rem"
                                        : "5.625rem",
                                    height: "auto",
                                    // display: "flex",
                                    // flex: 1,
                                }}
                            />
                        </Row>
                        <Typography
                            sx={{
                                mt: 2,
                                maxWidth: "34.75rem",
                                lineHeight:
                                    screenType === "sm"
                                        ? "1.8125rem"
                                        : "2.375rem",
                                fontSize: ["sm", "md"].includes(screenType)
                                    ? "0.9375rem"
                                    : "1.25rem",
                            }}
                        >
                            BOM's "Mood-Up Challenges" present a unique and
                            holistic approach to mental well-being, combining
                            consistent journal writing, mood inputs, and
                            meditation sound listening. These challenges provide
                            users with a structured and engaging framework to
                            cultivate healthy habits, fostering emotional
                            resilience and self-awareness. By integrating these
                            practices, BOM not only encourages regular
                            engagement but also adds a rewarding dimension
                            through token incentives, promoting a positive and
                            proactive mindset in users' mental health journeys.
                        </Typography>
                    </Box>
                    <Col
                        sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            alignSelf: "center",
                            // bgcolor: "green",
                            // flex: 1,
                            // paddingRight: "4vw",
                        }}
                    >
                        <img
                            src={group11}
                            style={{
                                width:
                                    screenType === "sm"
                                        ? "80vw"
                                        : screenType === "md"
                                        ? "70vw"
                                        : "30vw",
                                height: "auto",
                            }}
                            // style={{ width: "30vw", height: "auto" }}
                        />
                    </Col>
                </Row>
            </Col>
        </Col>
    );
};
const Section1 = () => {
    const { screenType } = useWindowDimensions();
    const pic1sz = {
        xl: 419.43,
        lg: 387.458,
        md: 219.469,
        sm: 138,
    } as any;
    const title1 = {
        xl: "4.9375rem",
        lg: "3.5rem",
        md: "3rem",
        sm: "2.25rem",
    } as any;
    const subTitle1 = {
        xl: "2.5rem",
        lg: "2rem",
        md: "1.5rem",
        sm: "1.25rem",
    } as any;

    // console.log("-----", px(420));
    return (
        <Row
            sx={{
                pt: 10,
                justifyContent: "center",
                pl: "4vw",
                // border: "1px solid red",
            }}
        >
            <Col
                sx={{
                    alignSelf: "flex-start",
                    mt: ["xl", "lg"].includes(screenType) ? "16vh" : "2vh",
                    // flex: 1,
                    justifyContent: "center",
                    mb: "4vh",
                    // minWidth: "41.25rem",
                }}
            >
                <Typography
                    sx={{
                        fontSize: title1[screenType],
                        // maxWidth: "41.25rem",
                        fontWeight: "700",
                        color: COLORS.main900,
                        whiteSpace: "pre",
                    }}
                >
                    {"A Bit of Mind,\nA Lot of Me:"}
                </Typography>
                <Typography
                    sx={{
                        fontSize: subTitle1[screenType],
                        mt: 1.5,
                        color: COLORS.gray900,
                        whiteSpace: "pre",
                    }}
                >
                    {"Using AI to Reward Your\nMental Harmony."}
                </Typography>
            </Col>
            <Box
                sx={{
                    display: "flex",
                    flex: 0.8,
                    // alignSelf: "flex-start",
                    // bgcolor: "yellow",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        paddingLeft: "14vw",
                        position: "relative",
                    }}
                >
                    <ReactSVG
                        src={coin1}
                        style={{
                            position: "absolute",
                            marginLeft: "-15%",
                            bottom: "10vh",
                            width: "30%",
                            height: "auto",
                        }}
                    />
                    <img
                        src={iPhone2p}
                        style={{
                            width: "60%",
                            minWidth: pic1sz[screenType],
                            height: "auto",
                        }}
                        // style={{ width: pic1sz[screenType], height: "auto" }}
                    />
                </Box>
            </Box>
        </Row>
    );
};
