import Root from "./root";
import ErrorPage from "./error-page";
import AboutPage from "./about-page";
import RoadmapPage from "./roadmap-page";
import DownloadPage from "./download-page";

const routes = [
    {
        route: "/home",
        name: "Home",
        key: "Home",
        component: <Root />,
        errorElement: <ErrorPage />,
    },
    {
        route: "/about",
        name: "About",
        key: "About",
        component: <AboutPage />,
        errorElement: <ErrorPage />,
    },
    {
        route: "/roadmap",
        name: "Road map",
        key: "Road map",
        component: <RoadmapPage />,
        errorElement: <ErrorPage />,
    },
    {
        route: "/download",
        name: "Download",
        key: "Download",
        component: <DownloadPage />,
        errorElement: <ErrorPage />,
    },
];

export default routes;
