import Footer from "../components/footer";
import Container from "../components/container";
import fonzz from "../assets/img/phonzz-blue.png";
import { Box, Typography } from "@mui/material";
import { COLORS } from "../values/colors";
import Row from "../components/row";
import { ReactSVG } from "react-svg";
import google from "../assets/img/google.svg";
import apple from "../assets/img/apple.svg";
import useWindowDimensions from "../helpers";

const DownloadPage = () => {
    const { screenType } = useWindowDimensions();
    const header1 = { xl: "44%", lg: "50%", md: "100%", sm: "100%" } as {
        [key: string]: string;
    };
    const header1BoxTopMargin = {
        xl: "30vh",
        lg: "20vh",
        md: "6vh",
        sm: "2vh",
    } as {
        [key: string]: string;
    };
    const header1BoxBottomPadding = {
        xl: "2vh",
        lg: "6vh",
        md: "80vh",
        sm: "100vh",
    } as {
        [key: string]: string;
    };
    const boxHeight = `${
        Number(header1BoxBottomPadding[screenType].replace("vh", "")) + 100
    }vh`;

    const bgImageSize = {
        xl: "50%",
        lg: "60%",
        md: "100%",
        sm: "100%",
    } as {
        [key: string]: string;
    };

    const fsTitle = { xl: "3rem", lg: "3rem", md: "3rem", sm: "2rem" } as {
        [key: string]: string;
    };

    console.log("=====", boxHeight, "scrn=", screenType);
    return (
        <Container scroll={true}>
            <Container containerProps={{ py: "10vh" }}>
                <div
                    style={{
                        backgroundImage: `url(${fonzz})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPositionX: ["sm", "md"].includes(screenType)
                            ? undefined
                            : "right",
                        backgroundPositionY: ["sm", "md"].includes(screenType)
                            ? 550
                            : "center",
                        backgroundSize: bgImageSize[screenType],
                        minHeight: "125vh",
                        width: "100%",
                        // border: "1px solid red",
                        // marginLeft: "40vw",
                        // marginTop: header1BoxTopMargin[screenType],
                    }}
                >
                    <Box
                        ml="8vw"
                        mt={header1BoxTopMargin[screenType]}
                        // pb={header1BoxBottomPadding[screenType]}
                        bgcolor={"rgba(255,255,255, .7)"}
                        p={4}
                        borderRadius={6}
                        // border={"1px solid red"}
                        maxWidth={header1[screenType]}
                    >
                        <Typography
                            sx={{
                                fontSize: fsTitle[screenType],
                                color: COLORS.main900,
                                fontWeight: "600",
                            }}
                        >
                            a bit of mind, a lot of me.
                        </Typography>
                        <Typography
                            sx={{
                                mt: 4,
                                fontSize:
                                    screenType === "sm" ? "1.5rem" : "3rem",

                                color: COLORS.gray700,
                                fontWeight: "600",
                            }}
                        >
                            {`Download the Bit of mind\nDApp (${
                                screenType === "sm" ? "" : "Coming"
                            } Soon)`}
                        </Typography>
                        <Row sx={{ mt: 8, gap: 4 }}>
                            <a href="https://apps.apple.com/kr/app/bom-ai/id6477849261?l=en-GB">
                                <ReactSVG
                                    src={apple}
                                    width={
                                        screenType !== "sm"
                                            ? "7.479rem"
                                            : "7.0625rem"
                                    }
                                    height={"auto"}
                                />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.bitofmind">
                                <ReactSVG
                                    src={google}
                                    width={
                                        screenType !== "sm"
                                            ? "8.4375rem"
                                            : "8.5rem"
                                    }
                                    height={"auto"}
                                />
                            </a>
                        </Row>
                    </Box>
                </div>
            </Container>

            <Footer />
        </Container>
    );
};

export default DownloadPage;
