import { Box, Button, Drawer } from "@mui/material";
import Row from "./row";
import { ReactSVG } from "react-svg";
import logo from "../assets/img/logo.svg";
import hamburger from "../assets/img/hamburger.svg";
import routes from "../routes";
import { COLORS } from "../values/colors";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from "../helpers";
import { useState } from "react";

const TopNav = () => {
    const [showDrawer, setShowDrawer] = useState(false);
    const currentRoute = useLocation();
    // console.log("TOPNAV CURR LOC=", currentRoute);
    const navigate = useNavigate();
    const { screenType } = useWindowDimensions();

    const drawerPress = () => {
        setShowDrawer(true);
    };

    const Routes = ({ orientation = "column" }) => {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: orientation,
                    flex: 1,
                    alignItems: "center",
                    justifyContent:
                        orientation != "column" ? "flex-end" : "flex-start",
                    pr: orientation === "row" ? "4vw" : 0,
                    // bgcolor: "green",
                }}
            >
                {routes.map((route: any, idx: number) => (
                    <Button
                        onClick={() => {
                            if (showDrawer) setShowDrawer(false);
                            navigate(route.route);
                        }}
                        key={`route-${route.name}-${idx}`}
                        style={{
                            color:
                                currentRoute.pathname === route.route &&
                                route.name !== "Download"
                                    ? COLORS.sec500
                                    : route.name === "Download"
                                    ? "white"
                                    : "black",
                            textDecoration: "none",
                            display: "block",
                            marginTop: "1vh",
                            backgroundColor:
                                idx === 3 &&
                                currentRoute.pathname !== route.route
                                    ? COLORS.main500
                                    : idx === 3
                                    ? COLORS.sec500
                                    : undefined,
                            borderRadius: ".75rem",
                            padding: "0.56rem 1.063rem",
                            fontSize: "1.0625rem",
                            fontWeight: "700",
                        }}
                    >
                        {route.name}
                    </Button>
                ))}
            </Box>
        );
    };

    return (
        <Row
            sx={{
                width: "100%",
                px: "8vw",
                pr: "4vw",
                pt: 1,
                justifyContent: "space-between",
                position: "fixed",
                zIndex: 9999,
                // bgcolor: "red",
                bgcolor: "rgba(255,255,255, .75)",
            }}
        >
            <ReactSVG
                src={logo}
                style={{
                    width: screenType === "sm" ? "7.6875rem" : "13rem",
                    height: "auto",
                }}
            />
            <Row
                sx={{
                    gap: 1,
                    flex: 1,
                    justifyContent: "flex-end",
                }}
            >
                {screenType === "xl" || screenType === "lg" ? (
                    <Routes orientation="row" />
                ) : (
                    <>
                        <Button sx={{ m: 0, p: 0 }} onClick={drawerPress}>
                            <ReactSVG
                                src={hamburger}
                                style={{ width: 20, height: 20 }}
                            />
                        </Button>
                        <Drawer
                            open={showDrawer}
                            onClose={() => setShowDrawer(false)}
                            // sx={{ bgcolor: "red" }}
                        >
                            <Box
                                sx={{
                                    p: 4,
                                }}
                            >
                                <Routes />
                            </Box>
                        </Drawer>
                    </>
                )}
            </Row>
        </Row>
    );
};

export default TopNav;
