import { Box, BoxProps, SxProps } from "@mui/material";
import React from "react";

interface IRow {
    children: React.ReactNode;
    sx?: SxProps;
}
const Row: React.FC<IRow> = ({ children, sx = {}, ...rest }) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                maxWidth: "100%",
                flexWrap: "wrap",
                ...sx,
            }}
            {...rest}
        >
            {children}
        </Box>
    );
};

export default Row;
