import { Box } from "@mui/material";

interface Container {
    containerProps: any;
    children: any;
    scroll?: boolean;
}
const Container: React.FC<any> = ({
    containerProps,
    children,
    scroll = false,
}) => {
    return (
        <Box
            style={{
                // border: "1px solid red",
                display: "flex",
                flexDirection: "column",
                flex: 1,
                overflowY: scroll ? "auto" : undefined,
                maxWidth: "100%",
            }}
            {...containerProps}
        >
            {children}
        </Box>
    );
};

export default Container;
