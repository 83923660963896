import { ReactSVG } from "react-svg";
import { Box, Button, IconButton, Typography } from "@mui/material";
import insta from "../assets/img/insta.svg";
import telegram from "../assets/icon/telegram.svg";
import x from "../assets/icon/x.svg";
import youtube from "../assets/icon/youtube.svg";
import logo from "../assets/img/logo.svg";
import routes from "../routes";
import { COLORS } from "../values/colors";
import { Link, useNavigate } from "react-router-dom";
import Row from "./row";
// import SendIcon from "@mui/icons-material/Send";
import { useState } from "react";

const Footer = () => {
    // const [email, setEmail] = useState("");
    // const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();

    // const submitHandler = async () => {
    //     try {
    //         if (isSubmitting || isInValid) return;
    //         setIsSubmitting(true);
    //         setEmail("");
    //     } catch (error) {
    //         console.error(error);
    //     } finally {
    //         setIsSubmitting(false);
    //     }
    // };

    // const isInValid = email === "";
    const thisYear = new Date().getFullYear();

    const social = [
        { id: "telegram", svg: telegram, link: "https://t.me/bitofmind" },
        { id: "x", svg: x, link: "https://twitter.com/BOM_BitOfMind" },
        {
            id: "youtube",
            svg: youtube,
            link: "https://www.youtube.com/@BitofMind",
        },
        ,
    ] as any[];

    const supportLinks = {
        "Terms of Service":
            "https://bitofminds-organization.gitbook.io/bom/disclaimers/disclaimer-and-legal-notice",
        "Privacy Policy":
            "https://bitofminds-organization.gitbook.io/bom/disclaimers/hippa-regulations-and-compliance-guidelines",
        Whitepaper: "https://bitofminds-organization.gitbook.io/bom/",
    };

    return (
        <Row
            sx={{
                width: "100%",
                bgcolor: "white",
                px: "8vw",
                py: 4,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
            }}
        >
            <Box id="left">
                <Box sx={{}}>
                    <ReactSVG
                        src={logo}
                        style={{ width: 202, height: "auto" }}
                    />
                    <p>Copyright &copy; {thisYear}</p>
                    <p>All rights reserved</p>
                </Box>
                <Box display="flex">
                    {social.map((social, idx) => (
                        <a href={social.link} target="_blank">
                            <ReactSVG
                                src={social.svg}
                                key={`${social}-${idx}`}
                            />
                        </a>
                    ))}
                </Box>
            </Box>
            <Box id="right">
                <Row
                    sx={{
                        gap: "4vw",
                        alignItems: "flex-start",
                    }}
                >
                    <Box>
                        <h1
                            style={{
                                color: COLORS.gray700,
                                marginTop: 0,
                            }}
                        >
                            Company
                        </h1>
                        {routes.map((route: any) => (
                            <Button
                                key={`route-${route.name}`}
                                sx={{
                                    color: COLORS.gray700,
                                    textDecoration: "none",
                                    display: "block",
                                    marginBottom: 0,
                                    p: 0,
                                    marginTop: "1vh",
                                    textTransform: "capitalize",
                                }}
                                onClick={() => navigate(route.route)}
                            >
                                <Typography
                                    sx={{
                                        whiteSpace: "pre",
                                        textAlign: "left",
                                    }}
                                >
                                    {route.name !== "Download"
                                        ? route.name
                                        : "Download"}
                                    {/* : "Download\n(Coming soon)"} */}
                                </Typography>
                            </Button>
                        ))}
                    </Box>

                    {/* <Box>
                        <h1 style={{ color: COLORS.gray700, marginTop: 0 }}>
                            Stay up to date
                        </h1>
                        <Row
                            sx={{
                                bgcolor: COLORS.main500,
                                p: 1,
                                borderRadius: ".5rem",
                            }}
                        >
                            <input
                                id="emailInput"
                                style={{
                                    backgroundColor: COLORS.main500,
                                    border: "none",
                                    boxShadow: "none",
                                }}
                                placeholder="Your email address"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <IconButton
                                onClick={submitHandler}
                                sx={{ boxShadow: "none" }}
                            >
                                <SendIcon
                                    sx={{ color: COLORS.main900 }}
                                    width={24}
                                    height={24}
                                />
                            </IconButton>
                        </Row>
                    </Box> */}
                    <Box>
                        <h1
                            style={{
                                color: COLORS.gray700,
                                marginTop: 0,
                            }}
                        >
                            Support
                        </h1>
                        {Object.entries(supportLinks).map(([key, value]) => (
                            <a
                                key={`support-${key}`}
                                style={{
                                    color: COLORS.gray700,
                                    textDecoration: "none",
                                    display: "block",
                                    marginTop: "1vh",
                                }}
                                href={value}
                                target="_blank"
                            >
                                <Typography>{key}</Typography>
                            </a>
                        ))}
                    </Box>
                </Row>
            </Box>
        </Row>
    );
};

export default Footer;
