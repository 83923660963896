import React from "react";
// import "./App.css";
import TopNav from "./components/top-nav";
import routes from "./routes";
import {
    Navigate,
    Route,
    RouteObject,
    RouterProps,
    Routes,
} from "react-router-dom";
import ScrollToTop from "./components/scrollToTop";

function App() {
    return (
        <div className="App">
            <TopNav />
            <ScrollToTop />
            <Routes>
                {routes.map((route: any) => (
                    <Route
                        path={route.route}
                        element={route.component}
                        key={route.key}
                    />
                ))}
                <Route path="*" element={<Navigate to="/home" />} />
            </Routes>
        </div>
    );
}

export default App;
