import { useState, useEffect } from "react";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // const px = (val: number) => {
    //     const originalRatio = 1440 / val;
    //     console.log("originalscale=", originalRatio);
    //     const currentWidth = windowDimensions.width;
    //     console.log("currentWidth=", currentWidth);
    //     const adjustMultiplier = originalRatio / currentWidth;
    //     console.log("adjustMultiplier=", adjustMultiplier);
    //     return val * adjustMultiplier;
    // };
    const screenType =
        windowDimensions.width > 1280
            ? "xl"
            : windowDimensions.width > 744
            ? "lg"
            : windowDimensions.width > 320
            ? "md"
            : "sm";

    return { windowDimensions, screenType };
}
