import { Box, Modal, Typography } from "@mui/material";
import Container from "../components/container";
import Footer from "../components/footer";
import { COLORS } from "../values/colors";
import Row from "../components/row";
import Col from "../components/col";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import useWindowDimensions from "../helpers";
import { ReactSVG } from "react-svg";
import tokenomics from "../assets/img/tokenomics.svg";
import { useState } from "react";

const TIMELINE = [
    {
        id: 0,
        title: "Q1",
        desc: ["BOM System Design"],
    },
    {
        id: 1,
        title: "Q2",
        desc: ["Tech Procurement"],
    },
    {
        id: 2,
        title: "Q3",
        desc: ["AI Algorithm Development"],
    },
    {
        id: 3,
        title: "Q4",
        desc: ["AI Model Training", "Launch Pad Funding"],
    },
    {
        id: 4,
        title: "Q1",
        desc: [
            "Beta App Services Launch",
            "-Journal",
            "-Mood Tracking",
            "-Self-Assessment Test",
            " DEX/CEX Listing",
        ],
    },
    {
        id: 5,
        title: "Q2",
        desc: ["AI Integration"],
    },
    {
        id: 6,
        title: "Q3",
        desc: ["-Peer to Peer Support Group", "-Virtual Counseling Program"],
    },
    {
        id: 7,
        title: "Q4",
        desc: ["Gamification of", "BOM Services"],
    },
    {
        id: 8,
        title: "Q1",
        desc: ["BOMCareFund Launch"],
    },
    {
        id: 9,
        title: "Q2",
        desc: ["BOM NFT Development"],
    },
    {
        id: 10,
        title: "Q3",
        desc: ["Beta AI Buddy", "BOM Wellness Hub Launch"],
    },
    {
        id: 11,
        title: "Q4",
        desc: ["BOMVerse Alpha Launch "],
    },
];

const Roadmap = () => {
    const [showModal, setShowModal] = useState(false);
    const { screenType } = useWindowDimensions();

    const textSizes = {
        ["header"]: { sm: "1.25rem", md: "2rem", lg: "3rem", xl: "3rem" },
        ["timeline"]: { sm: ".8rem", md: "1rem", lg: "1.25rem", xl: "1.5rem" },
    } as any;

    console.log("screenType=", screenType);
    return (
        <Container scroll={true}>
            <Container
                containerProps={{
                    py: "10vh",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                }}
            >
                <Col sx={{ mt: "3rem" }}>
                    <Typography
                        sx={{
                            fontWeight: "600",
                            fontSize: textSizes["header"][screenType],
                            color: COLORS.main900,
                            textAlign: "center",
                        }}
                    >
                        {`using AI to reward `}
                        <Typography
                            sx={{
                                fontSize: "inherit",
                                fontWeight: "inherit",
                                color: "black",
                                display:
                                    screenType === "sm" ? "block" : "inline",
                            }}
                        >
                            your mental journey
                        </Typography>
                    </Typography>
                </Col>

                <Col
                    sx={{
                        mt: 14,
                        alignItems: "center",
                        // border: "1px solid red",
                        width: "100%",
                    }}
                >
                    <Row sx={{ gap: 1 }}>
                        <Typography
                            sx={{ fontSize: "2rem", color: COLORS.gray700 }}
                        >
                            Our
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: "700",
                                fontSize: "2rem",
                                color: COLORS.gray700,
                            }}
                        >
                            Roadmap
                        </Typography>
                    </Row>
                    <Typography
                        sx={{
                            textAlign: "center",
                            mt: 12,
                            fontWeight: 700,
                            mb: "1vh",
                            fontSize: textSizes["timeline"][screenType],
                        }}
                    >
                        2023
                    </Typography>

                    <Timeline
                        position="alternate-reverse"
                        sx={{ mt: -2, flex: 1, width: "100%", px: "6vw" }}
                    >
                        {TIMELINE.map((item: any, idx: number) => (
                            <>
                                {[4, 8].includes(item.id) && (
                                    <Typography
                                        sx={{
                                            textAlign: "center",
                                            bgcolor:
                                                item.id === 4
                                                    ? COLORS.bg
                                                    : COLORS.sec100,
                                            p: 2,
                                            m: 4,
                                            fontWeight: "700",
                                            fontSize:
                                                textSizes["timeline"][
                                                    screenType
                                                ],
                                        }}
                                    >{`202${
                                        item.id === 4 ? 4 : 5
                                    }`}</Typography>
                                )}
                                <TimelineItem key={`timeline-itm-${idx}`}>
                                    <TimelineSeparator>
                                        <TimelineDot
                                            sx={{ bgcolor: "#F6A9BD" }}
                                        />
                                        <TimelineConnector
                                            sx={{ bgcolor: "#F6A9BD" }}
                                        />
                                    </TimelineSeparator>
                                    <TimelineContent
                                        sx={
                                            {
                                                // bgcolor: "gray",
                                                // width: ["sm", "md"].includes(
                                                //     screenType
                                                // )
                                                //     ? "100%"
                                                //     : "80%",
                                                // display: "flex",
                                                // bgcolor: "yellow",
                                                // border: "2px solid red",
                                            }
                                        }
                                    >
                                        <Col
                                            sx={{
                                                width: "100%",
                                                pl: ["sm", "md"].includes(
                                                    screenType
                                                )
                                                    ? 0
                                                    : "16vw",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: "700",
                                                    textAlign: "left",
                                                    // textAlign:
                                                    //     idx % 2
                                                    //         ? "left"
                                                    //         : "right",

                                                    fontSize:
                                                        textSizes["timeline"][
                                                            screenType
                                                        ],
                                                }}
                                            >
                                                {item.title}
                                            </Typography>
                                            {item.desc.map(
                                                (el: any, ind: number) => (
                                                    <Typography
                                                        key={`item-${el.title}-${ind}`}
                                                        sx={{
                                                            // textAlign:
                                                            //     idx % 2
                                                            //         ? "left"
                                                            //         : "right",
                                                            // whiteSpace: "pre",

                                                            textAlign: "left",
                                                            fontSize:
                                                                textSizes[
                                                                    "timeline"
                                                                ][screenType],
                                                            width: "100%",
                                                        }}
                                                    >
                                                        {el}
                                                    </Typography>
                                                )
                                            )}
                                        </Col>
                                    </TimelineContent>
                                </TimelineItem>
                            </>
                        ))}
                    </Timeline>
                </Col>
                <Col sx={{ mt: "18vh", width: "100%", px: "12vw" }}>
                    <Typography
                        sx={{
                            fontSize: "2rem",
                            fontWeight: "700",
                            color: COLORS.gray700,
                            mb: "4vh",
                            textAlign: "center",
                        }}
                    >
                        Tokenomics
                    </Typography>
                    <ReactSVG
                        src={tokenomics}
                        width="100%"
                        height="auto"
                        onClick={
                            screenType === "sm"
                                ? () => setShowModal(true)
                                : undefined
                        }
                    />
                </Col>
                {screenType == "sm" && (
                    <>
                        <Typography sx={{ mt: "8vh" }}>
                            Click to enlarge
                        </Typography>
                        <Modal
                            open={showModal}
                            onClose={() => setShowModal(false)}
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                overFlow: "scroll",
                            }}
                        >
                            <Box
                                sx={{
                                    flex: 1,
                                    display: "flex",
                                    bgcolor: "#ffffff",

                                    height: "200vh",
                                    width: "300vw",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <ReactSVG
                                    src={tokenomics}
                                    width={"50%"}
                                    height={"auto"}
                                    style={{
                                        width: "400vw",
                                        height: "auto",
                                    }}
                                />
                                <Typography
                                    sx={{
                                        textDecoration: "underline",
                                        position: "fixed",
                                        top: "4vh",
                                        right: "50%",
                                    }}
                                    onClick={() => setShowModal(false)}
                                >
                                    Close
                                </Typography>
                            </Box>
                        </Modal>
                    </>
                )}
            </Container>
            <Footer />
        </Container>
    );
};

export default Roadmap;
