import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import {
    BrowserRouter,
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import routes from "./routes";
import { createTheme, ThemeProvider } from "@mui/material";
import App from "./App";

const router = createBrowserRouter(routes);

const breakpoints = {
    values: {
        xs: 0,
        sm: 0, // Phone
        md: 768, // Tablet/Laptop
        lg: 1500, // Desktop
        xl: 2000,
    },
};

const theme = createTheme({
    breakpoints,
    typography: {
        fontFamily: ["Inter"].join(","),
    },
});

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </BrowserRouter>
    </React.StrictMode>
);
